/* Prevent scrolling */
body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden; /* Disable scrolling */
}

/* Center the QR Scanner and text */
.qr-scan-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full screen height */
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  background-color: #f4f4f4; /* Light background color */
}

/* Make the QR scanner container responsive */
.qr-scan-container h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.qr-scan-container p {
  font-size: 1rem;
  color: #333;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .qr-scan-container h2 {
    font-size: 1.2rem; /* Adjust heading font size on mobile */
  }

  .qr-scan-container p {
    font-size: 0.9rem; /* Adjust paragraph font size on mobile */
  }

  .qr-scan-container {
    padding: 10px; /* Reduce padding on mobile */
  }
}
